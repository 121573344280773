/* Made with love & passion by Appai @ Harnods*/

@import '../plugins/bootstrap-sass/assets/stylesheets/bootstrap';
@import '../plugins/font-awesome/scss/font-awesome';
@import '../plugins/owl.carousel/assets/owl.carousel';
@import '../plugins/jquery.bxslider/jquery.bxslider';
@import '../plugins/bootstrap-select/dist/css/bootstrap-select';
@import '../plugins/seiyria-bootstrap-slider/dist/css/bootstrap-slider.min.css';
@import '../plugins/autoComplete/jquery-ui.min.css';
@import '../plugins/jqzoom/css/jquery.jqzoom.css';
